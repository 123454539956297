define([
    'lodash',
    'coreUtils'
], function (
    _,
    coreUtils
) {
    'use strict';

    return {
        isUploadedFontFamily: coreUtils.fonts.isUploadedFontFamily,
        getUploadedFontFaceStyles: coreUtils.fonts.getUploadedFontFaceStyles
    };
});
