define([
    'lodash',
    'mobx'
], function (_, mobx) {
    'use strict';

    function createChildrenArray(arr, name) {
        if (!arr) {
            return undefined;
        }
        return mobx.observable.array(arr, name);
    }

    function observableArrayProperty(propertyName) {
        return {
            get() {
                return this.$observables[propertyName];
            },
            set(val) {
                if (!this.$observables[propertyName]) {
                    this.$observables[propertyName] = createChildrenArray(val, `StructureNode.${this.id}.${propertyName}`);
                } else if (val) {
                    const prop = this.$observables[propertyName];
                    if (!_.isEqual(prop.toJS(), val)) {
                        prop.replace(val);
                    }
                }
            },
            enumerable: true
        };
    }

    function simpleObservableProperty(propertyName) {
        return {
            get() {
                this.$observables[propertyName] = this.$observables[propertyName] || mobx.observable.shallowBox();
                return this.$observables[propertyName].get();
            },
            set(val) {
                if (!this.$observables[propertyName]) {
                    this.$observables[propertyName] = mobx.observable.shallowBox(val, `StructureNode.${this.id}.${propertyName}`);
                } else {
                    this.$observables[propertyName].set(val);
                }
            },
            enumerable: true
        };
    }

    function objectObservableProperty(propertyName) {
        return {
            get() {
                this.$observables[propertyName] = this.$observables[propertyName] || mobx.observable.shallowBox();
                return this.$observables[propertyName].get();
            },
            set(val) {
                if (!this.$observables[propertyName]) {
                    this.$observables[propertyName] = mobx.observable.shallowBox(_.clone(val), `StructureNode.${this.id}.${propertyName}`);
                } else {
                    const prop = this.$observables[propertyName];
                    if (!_.isEqual(prop.get(), val)) {
                        prop.set(_.clone(val));
                    }
                }
            },
            enumerable: true
        };
    }

    function StructureNode(structure) {
        if (structure instanceof StructureNode) {
            return structure;
        }

        this.id = structure.id;
        Object.defineProperty(this, '$observables', {enumerable: false, value: {}});
        _.forOwn(structure, (value, property) => {
            this[property] = value;
        });
    }

    Object.defineProperty(StructureNode.prototype, 'dataQuery', simpleObservableProperty('dataQuery'));
    Object.defineProperty(StructureNode.prototype, 'propertyQuery', simpleObservableProperty('propertyQuery'));
    Object.defineProperty(StructureNode.prototype, 'designQuery', simpleObservableProperty('designQuery'));
    Object.defineProperty(StructureNode.prototype, 'behaviorQuery', simpleObservableProperty('behaviorQuery'));
    Object.defineProperty(StructureNode.prototype, 'connectionQuery', simpleObservableProperty('connectionQuery'));
    Object.defineProperty(StructureNode.prototype, 'componentType', simpleObservableProperty('componentType'));
    Object.defineProperty(StructureNode.prototype, 'layout', objectObservableProperty('layout'));
    Object.defineProperty(StructureNode.prototype, 'modes', objectObservableProperty('modes'));
    Object.defineProperty(StructureNode.prototype, 'skin', simpleObservableProperty('skin'));
    Object.defineProperty(StructureNode.prototype, 'styleId', simpleObservableProperty('styleId'));
    Object.defineProperty(StructureNode.prototype, 'parent', simpleObservableProperty('parent'));

    Object.defineProperty(StructureNode.prototype, 'components', observableArrayProperty('components'));

    Object.defineProperty(StructureNode.prototype, 'isStructureNode', {enumerable: false, value: true});

    return StructureNode;
});
