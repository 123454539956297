define(['lodash'], function (_) {
    'use strict';

    function extractPageTitleFromOriginalTitle(originalTitle = '', withAddedSiteName, siteName) {
        if (withAddedSiteName || !_.includes(originalTitle, '|')) {
            return originalTitle;
        }

        const pageAndSiteList = originalTitle.split('|');
        const pageNamePieces = pageAndSiteList.filter(name => name.trim() !== siteName.trim()); // exclude siteTitle from name

        if (pageNamePieces.length) {
            originalTitle = pageNamePieces.join('|').trim(); // for in case user intentionally add pageName that contains `|` symbol
        } else { // site and page title are the same
            originalTitle = pageAndSiteList[0].trim();
        }

        return originalTitle;
    }

    return {
        extractPageTitleFromOriginalTitle
    };
});
