define([
    'lodash',
    'mobx',
    'coreUtils',
    'utils/core/data/observableData/StructureNode',
    'utils/core/data/observableData/wixMap'
], function (_, mobx, coreUtils, StructureNode, wixMap) {
    'use strict';

    function createDataMaps(pageData) {
        return _.mapValues(pageData, data => wixMap(_.clone(data)));
    }

    function createDataItemComputedFunction(dataMap, dataItemId, dataType, dataResolver, rootId) {
        return mobx.computed(function () {
            const data = dataMap.get(dataItemId);
            if (!data) {
                return {};
            }
            const id = data.type === 'Page' ? data.id : rootId;
            const resolved = dataResolver(data, id, dataType);
            return coreUtils.objectUtils.cloneDeep(resolved || data);
        }, {name: `dataItemComputed_${dataItemId}`});
    }

    function createResolvedDataMaps(dataMaps, dataResolver, rootId) {
        return _.transform(dataMaps, function (resolvedDataMaps, dataMap, dataType) {
            const resolvedComputedFunctions = _.transform(dataMap.keys(), function (acc, dataItemId) {
                acc[dataItemId] = createDataItemComputedFunction(dataMap, dataItemId, dataType, dataResolver, rootId);
            }, {});

            resolvedDataMaps[dataType] = mobx.observable.map(resolvedComputedFunctions);
        }, {});
    }

    function observeDataMapsChanges(dataMaps, queryMaps, dataResolver, rootId) {
        _.forOwn(dataMaps, function (dataMap, dataType) {
            dataMap.observe(function (changeObj) {
                const dataItemId = changeObj.name;

                switch (changeObj.type) {
                    case 'add':
                        queryMaps[dataType].set(dataItemId, createDataItemComputedFunction(dataMap, dataItemId, dataType, dataResolver, rootId));
                        break;
                    case 'delete':
                        queryMaps[dataType].delete(dataItemId);
                        break;
                }
            });
        });
    }

    const EMPTY_STRUCTURE = _(new StructureNode({})).keysIn().keyBy().mapValues(_.noop).value();

    function convertStructure(structure, useStructureNode) {
        const createStructureWrapper = useStructureNode ?
            compStructure => new StructureNode(compStructure) :
            compStructure => mobx.observable(_.defaults({}, compStructure, EMPTY_STRUCTURE));

        if (structure.DESKTOP) {
            return {
                DESKTOP: mobx.observable.shallowMap(_.mapValues(structure.DESKTOP, createStructureWrapper)),
                MOBILE: mobx.observable.shallowMap(_.mapValues(structure.MOBILE, createStructureWrapper))
            };
        }
        return createStructureWrapper(structure);
    }

    function createMobxPage(pageStructure, useStructureNode) {
        if (!pageStructure.structure || !pageStructure.data) {
            return pageStructure;
        }

        return mobx.observable.shallowObject(_.mapValues(pageStructure, function (value, key) {
            switch (key) {
                case 'structure':
                    return createMobxStructure(pageStructure.structure, useStructureNode);
                case 'data':
                    return mobx.observable.shallowObject(createDataMaps(pageStructure.data));
                default:
                    return value;
            }
        }));
    }

    const createMobxStructure = convertStructure;

    return {
        createMobxStructure,
        createMobxPage,
        createResolvedDataMaps,
        observeDataMapsChanges
    };
});
