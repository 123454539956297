define([
    'lodash',
    'mobx',
    'coreUtils',
    'utils/core/data/observableData/observableDataUtil'
], function (_, mobx, coreUtils, observableDataUtil) {
    'use strict';

    const getObservableMap = (value, isShallow) => isShallow ?
        mobx.observable.shallowMap(value) : mobx.observable.map(value);

    return isShallow => (siteData, path, value) => {
        if (path.length > 1) {
            return value;
        }

        try {
            return getObservableMap(value || {}, isShallow);
        } catch (e) {
            coreUtils.loggingUtils.logger.reportBI(siteData, coreUtils.loggingUtils.bi.errors.DATA_PLUGIN_ERROR, {
                store: _.head(path),
                path,
                value: JSON.stringify(value),
                stack: e.stack
            });
            const newValue = value ? observableDataUtil.cloneDeep(value) : {};
            return getObservableMap(newValue, isShallow);
        }
    };
});
