define([
    'lodash',
    'platformUtils',
    'coreUtils'
], function (
    _,
    platformUtils,
    coreUtils
) {
    'use strict';

    function sanitizeHTMLInjectionFromString(string) {
        if (string) {
            return string.replace(/["']/g, '').replace(/\n/g, ' ');
        }
    }

    function sanitizeHtmlInjection(object) {
        if (object) {
            return _(object)
                .mapKeys(function (value, key) {return sanitizeHTMLInjectionFromString(key);})
                .mapValues(function (value) {
                    if (_.isString(value)) {
                        return sanitizeHTMLInjectionFromString(value);
                    }

                    return value;
                }).value();
        }
    }

    function pickNonEmptyStringValues(object) {
        return _.pickBy(object, function (value) {
            return _.isString(value) && !_.isEmpty(value);
        });
    }

    function getSanitizedMainDynamicPagesSEOMetaTags(mainTags) {
        const pageSEOMetaTags = {};
        const mainMetaTagsSanitized = sanitizeHtmlInjection(mainTags);
        pageSEOMetaTags.description = mainMetaTagsSanitized.description;
        pageSEOMetaTags.keywords = mainMetaTagsSanitized.keywords;
        pageSEOMetaTags.robots = mainMetaTagsSanitized.noIndex === 'true' ? 'noindex' : 'index';
        return pageSEOMetaTags;
    }

    function convertImageLinkToExternalLink(imageLink, serviceTopology) {
        const parsedUri = platformUtils.mediaItemUtils.parseMediaItemUri(imageLink);

        if (parsedUri.error) {
            return imageLink;
        }

        return coreUtils.urlUtils.getMediaUrlByContext(parsedUri.mediaId, serviceTopology.staticMediaUrl, serviceTopology.mediaRootUrl);
    }

    function getSEOMetaTagsForDynamicPage(dynamicPageHeadData, defaultUrl, defaultTitle, serviceTopology) {
        if (!dynamicPageHeadData) {
            return {};
        }

        const mainMetaTagsRaw = _.omit(dynamicPageHeadData, ['metaTags', 'title']);
        const mainSEOMetaTags = getSanitizedMainDynamicPagesSEOMetaTags(mainMetaTagsRaw);
        const additionalSEOMetaTags = sanitizeHtmlInjection(dynamicPageHeadData.metaTags);
        if (additionalSEOMetaTags && additionalSEOMetaTags['og:image']) {
            additionalSEOMetaTags['og:image'] = convertImageLinkToExternalLink(additionalSEOMetaTags['og:image'], serviceTopology);
        }

        const defaultSEOData = {
            'og:title': defaultTitle,
            'og:url': defaultUrl
        };

        return _.assign(defaultSEOData,
            pickNonEmptyStringValues(mainSEOMetaTags),
            pickNonEmptyStringValues(additionalSEOMetaTags));
    }

    return {
        getSEOMetaTagsForDynamicPage,
        sanitizeHTMLInjectionFromString
    };
});
