define([
    'lodash',
    'mobx',
    'coreUtils',
    'utils/core/data/displayDataTransformPlugins/displayDataTransformer',
    'utils/core/data/observableData/observableDataUtil'
], function (_,
             mobx,
             coreUtils,
             displayDataTransformer,
             observableDataUtil) {
    'use strict';

    const DAL_ACTIONS = _.keyBy(['set', 'merge', 'push', 'remove']);

    coreUtils.wSpy.spyMobx(mobx);
    function isPathToPage(path) {
        return path[0] === 'pagesData' && path.length === 2;
    }

    function isInPagesData(path) {
        return path && path[0] === 'pagesData';
    }

    const CHILDREN_PROPERTY_NAMES = _.keyBy(['components', 'children', 'mobileComponents']);
    const ROOT_COMPONENT_PROPERTY_INDEX = 5;
    function getStructurePropertyIndex(path) {
        if (path.length > ROOT_COMPONENT_PROPERTY_INDEX + 1 && _.has(CHILDREN_PROPERTY_NAMES, path[ROOT_COMPONENT_PROPERTY_INDEX])) {
            const componentPropertyIndex = _.findLastIndex(path, function (value, index) {
                return _.has(CHILDREN_PROPERTY_NAMES, value) && !isNaN(Number(path[index + 1]));
            });
            return componentPropertyIndex + 2;
        }

        return ROOT_COMPONENT_PROPERTY_INDEX;
    }

    function setDataItemInShallowMapIfNeeded(jsonData, path, action) {
        if (path[2] !== 'data' || path.length < 5 || action !== DAL_ACTIONS.merge && path.length === 5) { // eslint-disable-line no-mixed-operators
            return false;
        }

        const pathToDataMap = path.slice(0, 4);
        const itemId = path[4];
        const pathToItem = path.slice(0, 5);
        const containingShallowMap = observableDataUtil.getByPath(jsonData, pathToDataMap);
        if (containingShallowMap && _.isFunction(containingShallowMap.set)) {
            const itemNewValue = coreUtils.objectUtils.cloneDeep(_.get(jsonData.pagesDataRaw, pathToItem));
            containingShallowMap.set(itemId, itemNewValue);
            return true;
        }

        return false;
    }

    function setStructurePropertyIfNeeded(jsonData, path) {
        if (path[2] !== 'structure' || path.length <= ROOT_COMPONENT_PROPERTY_INDEX) {
            return false;
        }

        const propertyIndex = getStructurePropertyIndex(path);
        if (propertyIndex !== -1 && path.length > propertyIndex) {
            const pathToProperty = path.slice(0, propertyIndex + 1);
            const propertyValue = coreUtils.objectUtils.cloneDeep(_.get(jsonData.pagesDataRaw, pathToProperty));
            observableDataUtil.setByPath(jsonData, pathToProperty, propertyValue);
            return true;
        }

        return false;
    }

    function updateParentInPagesDataIfPathIsInsideShallowObject(jsonData, path, action) {
        return isInPagesData(path) && (setDataItemInShallowMapIfNeeded(jsonData, path, action) || setStructurePropertyIfNeeded(jsonData, path));
    }

    function getRawValueFromPagesData(jsonData, path) {
        return _.has(jsonData.pagesDataRaw, path) ? _.get(jsonData.pagesDataRaw, path) : _.get(jsonData, path);
    }

    return {
        getByPath(jsonData, path, untracked, noClone) { // eslint-disable-line complexity
            const inPagesData = isInPagesData(path);
            if (untracked && inPagesData) {
                const value = getRawValueFromPagesData(jsonData, path);
                return noClone ? value : coreUtils.objectUtils.cloneDeep(value);
            }

            const data = observableDataUtil.getByPath(jsonData, path);
            if (!data) {
                return data;
            }

            if (inPagesData) {
                observableDataUtil.touch(data); // touch the array in a mobx way
                const value = getRawValueFromPagesData(jsonData, path);
                return noClone ? value : coreUtils.objectUtils.cloneDeep(value);
            }
            return noClone ? data : observableDataUtil.cloneDeep(data);
        },
        getObservableByPath(jsonData, path) {
            return observableDataUtil.getByPath(jsonData, path);
        },
        getItemInPath(json, path) {
            let object = json;
            if (object.pagesDataRaw && isInPagesData(path) && _.has(object.pagesDataRaw, path)) {
                return _.get(object.pagesDataRaw, path);
            }

            _.forEach(path, function (pathPart) {
                if (_.isUndefined(object)) {
                    return false;
                }
                if (mobx.isObservableMap(object) || _.has(object, 'WixMap')) {
                    object = object.get(pathPart);
                } else {
                    object = object[pathPart];
                }
            });
            return mobx.isObservable(object) ? mobx.toJS(object, false) : object;
        },
        setByPath(jsonData, path, data) {
            if (isInPagesData(path)) {
                _.set(jsonData.pagesDataRaw, path, data);
            }

            if (!updateParentInPagesDataIfPathIsInsideShallowObject(jsonData, path, DAL_ACTIONS.set)) {
                const dataToSet = displayDataTransformer.transformData(jsonData, path, data);
                observableDataUtil.setByPath(jsonData, path, dataToSet);
            }
        },
        mergeByPath(jsonData, pathToObject, obj) {
            const currValue = observableDataUtil.getByPath(jsonData, pathToObject);

            if (isInPagesData(pathToObject)) {
                const rawData = _.get(jsonData.pagesDataRaw, pathToObject);
                if (rawData) {
                    _.assign(rawData, obj);
                } else {
                    const parentRawData = _.get(jsonData.pagesDataRaw, _.dropRight(pathToObject));
                    _.set(parentRawData, _.last(pathToObject), obj);
                }
            }
            if (!updateParentInPagesDataIfPathIsInsideShallowObject(jsonData, pathToObject, DAL_ACTIONS.merge)) {
                const objToMerge = displayDataTransformer.transformData(jsonData, pathToObject, obj);
                observableDataUtil.mergeObservable(currValue, objToMerge);
            }
        },
        pushByPath(jsonData, index, dataArray, item, pathToArray) {
            if (isInPagesData(pathToArray)) {
                const rawArray = _.get(jsonData.pagesDataRaw, pathToArray);
                rawArray.splice(index, 0, item);
            }
            if (!updateParentInPagesDataIfPathIsInsideShallowObject(jsonData, pathToArray, DAL_ACTIONS.push)) {
                dataArray.splice(index, 0, item);
            }
        },
        removeByPath(jsonData, path) { // eslint-disable-line complexity
            const parentPath = _.initial(path);
            const keyOrIndexToDelete = _.last(path);
            const parentRawValue = isInPagesData(parentPath) && _.get(jsonData.pagesDataRaw, parentPath);
            const parentValue = observableDataUtil.getByPath(jsonData, parentPath);
            const parentIsArray = _.isArray(parentRawValue) || _.isArray(parentValue);

            if (parentIsArray) {
                if (parentRawValue) {
                    parentRawValue.splice(keyOrIndexToDelete, 1);
                }
                if (!updateParentInPagesDataIfPathIsInsideShallowObject(jsonData, path, DAL_ACTIONS.remove)) {
                    parentValue.splice(keyOrIndexToDelete, 1);
                }
            } else {
                if (parentRawValue) {
                    delete parentRawValue[keyOrIndexToDelete];
                }
                if (isPathToPage(path)) {
                    //TODO: pagesData should be an observable map. It's an observable obj, and we set obj[key] = undefined, but we need to delete it from the displayed
                    delete parentValue[keyOrIndexToDelete];
                    if (jsonData.resolvedDataMaps) {
                        jsonData.resolvedDataMaps.delete(keyOrIndexToDelete);
                    }
                } else if (!updateParentInPagesDataIfPathIsInsideShallowObject(jsonData, path, DAL_ACTIONS.remove)) {
                    observableDataUtil.removeFromObj(parentValue, keyOrIndexToDelete);
                }
            }
        },

        getKeysByPath(jsonData, path, untracked) {
            const inPagesData = isInPagesData(path);
            if (untracked && inPagesData) {
                return _.keys(getRawValueFromPagesData(jsonData, path));
            }

            const valueInPath = observableDataUtil.getByPath(jsonData, path);
            return observableDataUtil.getKeys(valueInPath);
        }
    };
});
