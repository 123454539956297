define(['lodash', 'mobx', 'coreUtils'], function (_, mobx, coreUtils) {
    'use strict';

    const DESKTOP = coreUtils.constants.VIEW_MODES.DESKTOP;
    const MOBILE = coreUtils.constants.VIEW_MODES.MOBILE;

    return function transform(siteData, path, value) {
        if (path.length > 1 || !_.get(value, 'compsToHide')) {
            return value;
        }

        const transformedRenderRealtimeConfig = _.defaults({
            compsToHide: {
                [DESKTOP]: mobx.observable.shallowMap(value.compsToHide[DESKTOP], 'compsToHideDesktop'),
                [MOBILE]: mobx.observable.shallowMap(value.compsToHide[MOBILE], 'compsToHideMobile')
            }
        }, value);

        return transformedRenderRealtimeConfig;
    };
});
