define(['lodash',
    'utils/core/data/displayDataTransformPlugins/displayDataTransformer',
    'utils/core/data/displayDataTransformPlugins/aspectDataTransformPlugin',
    'utils/core/data/displayDataTransformPlugins/pageDataTransformPlugin',
    'utils/core/data/displayDataTransformPlugins/renderFlagsTransformPlugin',
    'utils/core/data/displayDataTransformPlugins/initRootMapTransformPlugin',
    'utils/core/data/displayDataTransformPlugins/platformTransformPlugin',
    'utils/core/data/displayDataTransformPlugins/renderRealtimeConfigTransformPlugin',
    'utils/core/data/displayDataTransformPlugins/rendererModelTransformPlugin'
], function (_,
             displayDataTransformer,
             aspectDataTransformPlugin,
             pageDataTransformPlugin,
             renderFlagsTransformPlugin,
             initRootMapTransformPlugin,
             platformTransformPlugin,
             renderRealtimeConfigTransformPlugin,
             rendererModelTransformPlugin) {
    'use strict';

    const deepMapRootTransformPlugin = initRootMapTransformPlugin(false);
    const shallowMapRootTransformPlugin = initRootMapTransformPlugin(true);

    displayDataTransformer.registerTransformPlugin('siteAspectsData', aspectDataTransformPlugin);
    displayDataTransformer.registerTransformPlugin('pagesData', pageDataTransformPlugin);
    displayDataTransformer.registerTransformPlugin('renderFlags', renderFlagsTransformPlugin);
    displayDataTransformer.registerTransformPlugin('pageStubComponents', shallowMapRootTransformPlugin);
    displayDataTransformer.registerTransformPlugin('nativeComponents', shallowMapRootTransformPlugin);
    displayDataTransformer.registerTransformPlugin('wixappsRenderCounters', deepMapRootTransformPlugin);
    displayDataTransformer.registerTransformPlugin('customUrlMapping', deepMapRootTransformPlugin);
    displayDataTransformer.registerTransformPlugin('svgShapes', deepMapRootTransformPlugin);
    displayDataTransformer.registerTransformPlugin('platformWidgetsState', deepMapRootTransformPlugin);
    displayDataTransformer.registerTransformPlugin('platform', platformTransformPlugin);
    displayDataTransformer.registerTransformPlugin('mediaQualityStore', deepMapRootTransformPlugin);
    displayDataTransformer.registerTransformPlugin('activeModes', deepMapRootTransformPlugin);
    displayDataTransformer.registerTransformPlugin('resolvedDataMaps', deepMapRootTransformPlugin);
    displayDataTransformer.registerTransformPlugin('mobileStructures', deepMapRootTransformPlugin);
    displayDataTransformer.registerTransformPlugin('rendererModel', rendererModelTransformPlugin);
    displayDataTransformer.registerTransformPlugin('renderRealtimeConfig', renderRealtimeConfigTransformPlugin);
});
