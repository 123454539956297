define(['lodash'], function (_) {
    'use strict';

    function move(ref, to, from) {
        if (!get(from, ref)) {
            return;
        }

        if (!get(to, ref)) {
            set(to, ref, get(from, ref));
        }
        remove(from, ref);
    }

    function get(parentData, ref) {
        return ref && parentData[ref.replace('#', '')];
    }

    function set(parentData, ref, dataToSet) {
        if (ref) {
            parentData[ref.replace('#', '')] = dataToSet;
        }
    }

    function remove(parentData, ref) {
        if (ref) {
            delete parentData[ref.replace('#', '')];
        }
    }

    function moveMediaRef(parentData, mediaRef, masterData) {
        // Image or WixVideo
        const media = get(parentData, mediaRef);
        if (!media) {
            return;
        }

        move(mediaRef, masterData, parentData);
        // Image
        move(media.posterImageRef, masterData, parentData);
    }

    function moveBgMedia(pageId, bgImage, masterPageData, pageData) {
        if (bgImage) {
            if (bgImage.mediaRef) {
                moveMediaRef(pageData, bgImage.mediaRef, masterPageData);
            }
            //Image
            move(bgImage.imageOverlay, masterPageData, pageData);
        }
    }

    const pageRefs = [
        ['ogImageRef'],
        ['pageBackgrounds', 'desktop', 'ref'],
        ['pageBackgrounds', 'mobile', 'ref']
    ];

    const backgroundMediaRefs = [
        ['mediaRef'],
        ['imageOverlay']
    ];

    const wixVideoRefs = [
        ['posterImageRef']
    ];
    const REFS_FROM_ITEM = {
        BackgroundMedia: backgroundMediaRefs,
        AppPage: pageRefs,
        Page: pageRefs,
        WixVideo: wixVideoRefs
    };

    const getRefs = item => {
        if (!item) {
            return {};
        }
        const refPaths = REFS_FROM_ITEM[item.type] || [];
        return _(refPaths)
            .filter(path => _.get(item, path))
            .transform((refs, path) => {
                const refId = _.get(item, path).replace(/^#/, '');
                refs[refId] = path;
            }, {})
            .value();
    };

    const getRefGraph = (id, pageData, translationData, lang, pageId) => {
        const graph = {};
        const addItem = (itemId, parent = '') => {
            const node = {
                parent,
                paths: {
                    translation: translationData[itemId] && ['translations', lang, 'data', 'document_data'],
                    original: pageData[itemId] && ['data', 'document_data']
                },
                pageId,
                id: itemId,
                refs: _.assign(getRefs(translationData[itemId]), getRefs(pageData[itemId]))
            };

            graph[itemId] = node;
            _.forEach(node.refs, (refPath, refId) => addItem(refId, node.id));
            return node;
        };

        addItem(id);
        return graph;
    };

    const removeRefsToNonexistingItems = (graph, page, sendBiError = _.noop) => _(graph)
        .filter(({paths, parent}) => parent && !paths.translation && !paths.original)
        .forEach(({id: referenced, parent: parentId, pageId}) => {
            const parent = graph[parentId];
            const refPath = parent.refs[referenced];
            const translationPath = parent.paths.translation;
            const originalPath = parent.paths.original;
            if (translationPath && originalPath) {
                sendBiError(pageId);
                const originalRef = _.get(page, [...originalPath, parentId, ...refPath]);
                _.set(page, [...translationPath, parentId, ...refPath], originalRef);
                delete graph[referenced];
            }
        });

    const getPageGraph = (page, lang, pageId) => {
        const pageDocumentData = page.data.document_data;
        const translatedDocData = lang ? _.get(page.translations, [lang, 'data', 'document_data'], {}) : {};
        return getRefGraph(pageId, pageDocumentData, translatedDocData, lang, pageId);
    };

    function moveDataAndRefsToMasterpage(masterPage, page, lang = '', sendBiError) {
        const pageId = page.structure.id;
        const graph = getPageGraph(page, lang, pageId);
        _.forEach(graph, ({
            id,
            paths
        }) => {
            _(paths).values().compact().forEach(path => {
                const itemPath = path.concat(id);
                const itemToMove = _.get(page, itemPath);
                if (!itemToMove) {
                    return;
                }

                const existingItem = _.get(masterPage, itemPath);
                if (!existingItem) {
                    _.set(masterPage, itemPath, itemToMove);
                }
                _.unset(page, itemPath);
            });
        });
        const masterPageGraph = getPageGraph(masterPage, lang, pageId);

        removeRefsToNonexistingItems(masterPageGraph, masterPage, sendBiError);
    }

    const movePageDataToMaster = (page, masterPage, sendBiError = _.noop) => {
        if (!page.structure) {
            return;
        }

        if (masterPage.translations && page.translations) {
            Object.keys(page.translations)
                .forEach(lang => {
                    moveDataAndRefsToMasterpage(masterPage, page, lang, sendBiError);
                });
        } else {
            moveDataAndRefsToMasterpage(masterPage, page, '', sendBiError);
            if (masterPage.translations) {
                Object.keys(masterPage.translations)
                    .forEach(lang => {
                        const masterPageGraph = getPageGraph(masterPage, lang, page.structure.id);
                        removeRefsToNonexistingItems(masterPageGraph, masterPage, sendBiError);
                    });
            }
        }
    };

    const movePageDataFromMaster = (page, masterPage) => {
        if (!page.structure) {
            return;
        }

        function movePageBackgroundData(pageId, pageData, masterData) {
            let desktopBg;
            let mobileBg;

            // Pages or AppPages
            const pageItem = get(masterData, pageId);
            move(pageId, pageData, masterData);

            if (pageItem && pageItem.pageBackgrounds && pageItem.pageBackgrounds.desktop.ref) {
                // Return if backgroundImage is already present on masterPage document data
                const pageDesktopBg = get(pageData, pageItem.pageBackgrounds.desktop.ref);
                if (pageDesktopBg) {
                    return;
                }
                // BackgroundImage or BackgroundMedia
                desktopBg = get(masterData, pageItem.pageBackgrounds.desktop.ref);
                mobileBg = get(masterData, pageItem.pageBackgrounds.mobile.ref);
                move(pageItem.pageBackgrounds.desktop.ref, pageData, masterData);
                move(pageItem.pageBackgrounds.mobile.ref, pageData, masterData);

                //BackgroundMedia
                moveBgMedia(pageItem.id, desktopBg, pageData, masterData);
                moveBgMedia(pageItem.id, mobileBg, pageData, masterData);
            }
        }

        const masterPageDocumentData = masterPage.data.document_data;
        const pageDocumentData = page.data.document_data;
        const pageId = page.structure.id;
        movePageBackgroundData(pageId, pageDocumentData, masterPageDocumentData);
        if (masterPage.translations && page.translations) {
            const languages = Object.keys(page.translations);
            languages.forEach(language => {
                if (masterPage.translations[language]) {
                    const translatedPage = page.translations[language].data.document_data;
                    const masterTranslationData = masterPage.translations[language].data.document_data;
                    const translatedElements = Object.assign({}, masterPage.data.document_data, translatedPage);
                    movePageBackgroundData(pageId, translatedElements, masterTranslationData);
                }
            });
        }
    };
    const getDataPointer = (pageId, dataId) => ({pageId, path: ['data', 'document_data', dataId.replace('#', '')]});

    const getPageBackgroundDataPointers = (page, pageIds) => {
        const dataMap = page.data.document_data;
        const dataIds = pageIds.slice().map(pageId => getDataPointer(pageId, pageId));
        return dataIds.reduce((allIds, {pageId}) => {
            const addDataItem = id => dataIds.push(getDataPointer(pageId, id));
            const pageDataItem = get(dataMap, pageId);
            if (pageDataItem && pageDataItem.pageBackgrounds && get(dataMap, pageDataItem.pageBackgrounds.desktop.ref)) {
                const desktopBg = get(dataMap, pageDataItem.pageBackgrounds.desktop.ref);
                const mobileBg = get(dataMap, pageDataItem.pageBackgrounds.mobile.ref);
                [desktopBg, mobileBg].forEach(bg => {
                    if (bg) {
                        addDataItem(bg.id);
                        if (bg.mediaRef) {
                            addDataItem(bg.mediaRef);
                            const mediaItem = get(dataMap, bg.mediaRef);
                            if (mediaItem && mediaItem.posterImageRef) {
                                addDataItem(mediaItem.posterImageRef);
                            }
                        }
                        if (bg.imageOverlay) {
                            addDataItem(bg.imageOverlay);
                        }
                    }
                });
            }
            return allIds;
        }, dataIds);
    };

    return {
        getPageBackgroundDataPointers,
        movePageDataToMaster,
        movePageDataFromMaster
    };
});
