define(['lodash', 'mobx'], function (_, mobx) {
    'use strict';

    function transformAspectData(aspectData) {
        return mobx.observable.shallowObject({
            globalData: _.isUndefined(aspectData.globalData) ? undefined : aspectData.globalData,
            dataByCompId: mobx.observable.shallowMap(aspectData.dataByCompId || {})
        });
    }

    return function transform(siteData, path, value) {
        if (path.length === 1) {
            const transformedAspectDatas = _.mapValues(value, transformAspectData);
            return mobx.observable.shallowMap(transformedAspectDatas);
        }

        if (path.length === 2) {
            return transformAspectData(value);
        }

        return value;
    };
});
