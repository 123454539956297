define(['lodash'], function (_) {
    'use strict';

    const pluginsMap = {}; //eslint-disable-line santa/no-module-state

    function registerTransformPlugin(storeName, transformFn) {
        if (_.has(pluginsMap, storeName)) {
            throw new Error(`path ${storeName} already registered in display data transform plugins`);
        }

        pluginsMap[storeName] = transformFn;
    }

    function transformData(jsonData, path, data) {
        const storeName = _.head(path);
        const transformFunc = pluginsMap[storeName];

        if (transformFunc) {
            return transformFunc(jsonData, path, data);
        }

        return data;
    }

    return {
        transformData,
        registerTransformPlugin
    };
});
