define([
    'lodash',
    'coreUtils',
    'dataFixer',
    'utils/fonts/uploadedFontsUtils',
    'utils/mixins/postMessageCompMixin',
    'utils/socialAPI/socialAPI',
    'utils/routers/routersBackEndRequests',
    'utils/core/FullSiteData',
    'utils/seo/seoUtils',
    'utils/style/styleUtils',
    'utils/core/data/observableData/observableDataUtil',
    'utils/page/pageUtils',
    'utils/site/siteUtils',

    // side effects
    'utils/core/data/displayedDataPlugins'
], function (
    _,
    coreUtils,
    dataFixer,
    uploadedFontsUtils,
    postMessageCompMixin,
    socialAPI,
    routersBackEndRequests,
    FullSiteData,
    seoUtils,
    styleUtils,
    observableDataUtil,
    pageUtils,
    siteUtils
) {
    'use strict';

    /**
     * @exports utils
     */
    const exports = {
        siteUtils,
        displayedOnlyStructureUtil: coreUtils.displayedOnlyStructureUtil,
        renderDoneMixin: coreUtils.renderDoneMixin,
        logger: coreUtils.loggingUtils.logger,
        log: coreUtils.log,
        deprecatedSiteModelMigrater: dataFixer.deprecatedSiteModelMigrater,
        htmlTransformer: coreUtils.htmlTransformer,
        textSecurityFixer: coreUtils.textSecurityFixer,
        htmlParser: coreUtils.htmlParser,
        throttleUtils: coreUtils.throttleUtils,
        keyboardUtils: coreUtils.keyboardUtils,
        classNames: coreUtils.classNames,
        cookieUtils: coreUtils.cookieUtils,
        dataFixer,
        tween: coreUtils.tween,
        urlUtils: coreUtils.urlUtils,
        routersBackEndRequests,
        Store: coreUtils.Store,
        mobileViewportFixer: coreUtils.mobileViewportFixer,
        dataUtils: coreUtils.dataUtils,
        seoUtils,
        dateTimeUtils: coreUtils.dateTimeUtils,
        validationUtils: coreUtils.validationUtils,
        ajaxLibrary: coreUtils.ajaxLibrary,
        menuUtils: coreUtils.menuUtils,
        fileUploadManager: coreUtils.fileUploadManager,
        cssUtils: coreUtils.cssUtils,
        linkRenderer: coreUtils.linkRenderer,
        fonts: {
            fontUtils: coreUtils.fontUtils,
            uploadedFontsUtils
        },
        triggerTypesConsts: coreUtils.triggerTypesConsts,
        wixUrlParser: coreUtils.wixUrlParser,
        wixUserApi: coreUtils.wixUserApi,
        MobileDeviceAnalyzer: coreUtils.MobileDeviceAnalyzer,
        FullSiteData,
        SiteDataPrivates: coreUtils.SiteDataPrivates,
        BootstrapSiteData: coreUtils.SiteData,
        hashUtils: coreUtils.hashUtils,
        guidUtils: coreUtils.guidUtils,
        arrayUtils: coreUtils.arrayUtils,
        stringUtils: coreUtils.stringUtils,
        mediaZoomCalculations: coreUtils.mediaZoomCalculations,
        objectUtils: coreUtils.objectUtils,
        animationFrame: coreUtils.animationFrame,
        scrollAnchors: coreUtils.scrollAnchors,
        layoutAnchors: coreUtils.layoutAnchors,
        originalValuesMapGenerator: coreUtils.originalValuesMapGenerator,
        domMeasurements: coreUtils.domMeasurements,
        storage: coreUtils.storage,
        style: coreUtils.style,
        galleriesCommonLayout: coreUtils.galleriesCommonLayout,
        requestsUtil: coreUtils.requestsUtil,
        imageTransformDataFixers: dataFixer.imageTransformDataFixers,
        boundingLayout: coreUtils.boundingLayout,
        siteConstants: coreUtils.siteConstants,
        languages: coreUtils.languages,
        getComponentsAnchorsMetaData: coreUtils.getComponentsAnchorsMetaData,
        verticalMenuCalculations: coreUtils.verticalMenuCalculations,
        appPartMediaInnerViewNameUtils: coreUtils.appPartMediaInnerViewNameUtils,
        anchorCyclesHelper: dataFixer.anchorCyclesHelper,
        postMessageCompMixin,
        colorParser: coreUtils.colorParser,
        reactComponentUtils: coreUtils.reactComponentUtils,
        dockUtils: coreUtils.dockUtils,
        layout: coreUtils.layoutUtils,
        positionAndSize: coreUtils.positionAndSize,
        viewportUtils: coreUtils.viewportUtils,
        containerBackgroundUtils: coreUtils.containerBackgroundUtils,
        svgFilters: coreUtils.svgFilters,
        svgFeatureDetection: coreUtils.svgFeatureDetection,
        math: coreUtils.math,
        socialAPI,
        functionUtils: coreUtils.functionUtils,
        constants: coreUtils.constants,
        siteRenderPrivateStuff: {
            fullToDisplayedJson: coreUtils.fullToDisplayedJson
        },
        modes: coreUtils.modesUtils,
        structureDimensions: coreUtils.structureDimensions,
        performance: coreUtils.loggingUtils.performance,
        performanceMetrics: coreUtils.loggingUtils.performanceMetrics,
        newrelic: coreUtils.loggingUtils.newrelic,
        mediaConsts: coreUtils.mediaConsts,
        balataConsts: coreUtils.mediaConsts.balataConsts,
        blogAppPartNames: coreUtils.blogAppPartNames,
        integrations: _.assign({}, coreUtils.integrations),
        santaTypes: coreUtils.santaTypes,
        dataCapsule: coreUtils.integrations.dataCapsuleWrapper,
        observableDataUtil,
        //TODO: remove when editor that points directly to imageClientApi is in prod.
        imageTransform: coreUtils.imageClientApi,
        socialShareHandler: coreUtils.socialShareHandler,
        socialCounterDatabaseAPI: coreUtils.socialCounterDatabaseAPI,
        errorPages: coreUtils.errorPages,
        logWixCodeConsoleMessage: coreUtils.logWixCodeConsoleMessage,
        componentUtils: coreUtils.componentUtils,
        sessionFreezer: coreUtils.sessionFreezer,
        styleUtils,
        xss: {
            filterHtmlString: coreUtils.filterHtmlString
        },
        pageUtils
    };
    return exports;
});
