define(['lodash', 'mobx'], function (_, mobx) {
    'use strict';

    return function transform(siteData, path, value) {
        if (path.length === 1) {
            return mobx.observable({
                appState: mobx.observable.map(_.get(value, 'appState') || {}),
                appManifest: mobx.observable.map(_.get(value, 'appManifest') || {}),
                appPublicApiName: mobx.observable.map(_.get(value, 'appPublicApiName') || {})
            });
        }

        return value;
    };
});

